import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { produce } from "immer";
import styled from "@emotion/styled";
import { findAccountById } from "../../../utilities/general_util";
import UserContext from "../../../assets/user_context";

const AccountDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3rem;
  background-color: #80a4ad;
  padding: 0.5rem;
  padding-left: 1rem;
  width: 250px;
`;

const SubAccountDiv = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 3rem;
  background-color: #acd4a0;
  padding: 0.5rem;
  padding-left: 1rem;
  width: 220px;
  margin-bottom: 0.5rem;
`;

const GroupDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 3rem;
  background-color: #e7eff1;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-left: 2rem;
  width: 350px;
  margin: 0.5rem;
`;

const findAccount = (groups, id) => {
  console.log("Finding, ", groups, id); // Debugging line
  // Loop through groups and accounts to find the account by ID
  // If needed, make this function recursive to find deeply nested accounts
  for (const groupObj of groups) {
    for (const account of groupObj.accounts) {
      if (account.id === id) {
        return account;
      }
    }
  }
  return null;
};

const TypeRearrange = ({ groupsIn, type, setDraftGroups }) => {
  const { accounts } = useContext(UserContext);
  const [groups, setGroups] = useState(groupsIn);

  useEffect(() => {
    console.log("setting draft groups");
    setDraftGroups((prev) => {
      const newDraft = prev;
      console.log("newDraft:", newDraft); // Debugging line
      //Find the array position of the type
      const typeIndex = newDraft.findIndex((typeObj) => typeObj.type === type);
      console.log("typeIndex:", typeIndex); // Debugging line
      //Replace the groups array with the new groups array
      newDraft[typeIndex].groups = groups;
      console.log("newDraft:", newDraft); // Debugging line
      return newDraft;
    });
  }, [groups, type, setDraftGroups]);

  const renderSubDraggables = (item) => (
    <ul>
      {item?.subAccounts?.length > 0 &&
        item.subAccounts.map((subItem, index) => (
          <Draggable
            key={subItem}
            draggableId={`${item.id}-${subItem}`}
            index={index}>
            {(provided) => (
              <SubAccountDiv
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                {`${
                  findAccountById({ accountId: subItem, accounts })
                    .accountNumber
                } - ${
                  findAccountById({ accountId: subItem, accounts }).accountName
                }`}
              </SubAccountDiv>
            )}
          </Draggable>
        ))}
    </ul>
  );

  // Recursive function to find and remove an item by its index and group ID
  const findAndRemoveItem = (groups, groupId, index) => {
    for (const groupObj of groups) {
      if (groupObj.groupName === groupId) {
        return groupObj.accounts.splice(index, 1)[0];
      }
      console.log(groupObj);
      if (Array.isArray(groupObj.accounts)) {
        for (const account of groupObj.accounts) {
          if (account?.subAccounts) {
            const found = findAndRemoveItem(
              account.subAccounts,
              groupId,
              index,
            );
            if (found) {
              return found;
            }
          }
        }
      }
    }
  };

  const onDragEnd = (result) => {
    const { source, destination, combine, draggableId } = result;
    console.log(result);
    if (!destination && !combine) return;

    setGroups(
      produce((draft) => {
        let moved;
        if (result.type === "group") {
          console.log("Handling group movement");
          const [movedGroup] = draft.splice(source.index, 1);
          draft.splice(destination.index, 0, movedGroup);
        } else if (destination) {
          console.log("Source:", source); // Debugging line
          console.log("draggableId:", draggableId); // Debugging line
          if (draggableId.includes("-")) {
            // Debugging lines
            console.log("Handling subAccount to parent movement");

            //Get ID's of parent and sub
            const parentAccountId = draggableId.split("-")[0];
            const subAccountId = draggableId.split("-")[1];
            const parentAccount = findAccount(draft, parentAccountId);
            console.log("Found parent account:", parentAccount); // Debugging line

            //Remove subAccount from parentAccount's subAccounts array
            if (parentAccount) {
              const subAccountIndex =
                parentAccount.subAccounts.indexOf(subAccountId);
              console.log("subAccountIndex:", subAccountIndex); // Debugging line
              if (subAccountIndex !== -1) {
                parentAccount.subAccounts.splice(subAccountIndex, 1);
              }
            }

            const newParentAccount = {
              id: subAccountId,
              subAccounts: [],
            };
            console.log("New Parent Account:", newParentAccount); // Debugging line

            const toGroup =
              draft.find((groupObj) =>
                groupObj.accounts.some(
                  (acct) => acct.id === destination.droppableId,
                ),
              ) ||
              draft.find(
                (groupObj) => groupObj.groupName === destination.droppableId,
              );

            console.log("toGroup:", toGroup, destination); // Debugging line
            if (toGroup) {
              toGroup.accounts.splice(destination.index, 0, newParentAccount);
            }
          } else {
            console.log("Handling normal account movement");

            const fromGroup = draft.find(
              (groupObj) => groupObj.groupName === source.droppableId,
            );
            const toGroup = draft.find(
              (groupObj) => groupObj.groupName === destination.droppableId,
            );

            if (fromGroup && toGroup) {
              const [movedAccount] = fromGroup.accounts.splice(source.index, 1);
              toGroup.accounts.splice(destination.index, 0, movedAccount);
            }
          }
        }
        if (combine) {
          if (!moved) {
            moved = findAndRemoveItem(draft, source.droppableId, source.index);
          }
          console.log("Moved:", moved); // Debugging line
          if (draggableId.includes("-")) {
            // Debugging lines
            console.log("Handling subAccount to subAccount movement");

            const parentAccountId = draggableId.split("-")[0];
            const subAccountId = draggableId.split("-")[1];
            const parentAccount = findAccount(draft, parentAccountId);
            console.log("Found parent account:", parentAccount);

            //Remove subAccount from parentAccount's subAccounts array
            if (parentAccount) {
              const subAccountIndex =
                parentAccount.subAccounts.indexOf(subAccountId);
              console.log("subAccountIndex:", subAccountIndex); // Debugging line
              if (subAccountIndex !== -1) {
                parentAccount.subAccounts.splice(subAccountIndex, 1);
              }
            }
          }
          const toGroup = draft.find(
            (groupObj) => groupObj.groupName === combine.droppableId,
          );
          const parentItem = toGroup.accounts.find(
            (account) => account.id === combine.draggableId,
          );
          if (parentItem) {
            if (parentItem?.subAccounts?.length > 0) {
              //Add to a parent account's subAccounts array
              parentItem.subAccounts.push(moved.id);
              if (moved.subAccounts?.length > 0) {
                moved.subAccounts.forEach((subAcct) =>
                  parentItem.subAccounts.push(subAcct),
                );
              }
            } else {
              //Create a new subAccounts array for the parent account

              parentItem["subAccounts"] = [moved.id];
              if (moved.subAccounts?.length > 0) {
                moved.subAccounts.forEach((subAcct) =>
                  parentItem.subAccounts.push(subAcct),
                );
              }
            }
          }
        }
      }),
    );
  };

  useEffect(() => {
    console.log("groups:", groups); // <-- Log here
  }, [groups]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="all-groups" type="group">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ display: "flex", flexDirection: "row" }}>
            {groups.map((groupObj, groupIndex) => (
              <Draggable
                key={groupObj.groupName}
                draggableId={groupObj.groupName}
                index={groupIndex}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}>
                    <Droppable
                      droppableId={groupObj.groupName}
                      key={groupIndex}
                      isCombineEnabled>
                      {(provided) => (
                        <GroupDiv
                          ref={provided.innerRef}
                          {...provided.droppableProps}>
                          <h2>{groupObj.groupName}</h2>
                          {groupObj.accounts.map((account, index) => (
                            <Draggable
                              key={account.id}
                              draggableId={account.id}
                              index={index}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}>
                                  <AccountDiv>
                                    {`${
                                      findAccountById({
                                        accountId: account.id,
                                        accounts,
                                      }).accountNumber
                                    } - ${
                                      findAccountById({
                                        accountId: account.id,
                                        accounts,
                                      }).accountName
                                    }`}
                                  </AccountDiv>
                                  <Droppable
                                    droppableId={`${account.id}`}
                                    isCombineEnabled>
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}>
                                        {renderSubDraggables(account)}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </GroupDiv>
                      )}
                    </Droppable>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default TypeRearrange;
