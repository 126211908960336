import React, { useState, useContext } from "react";
import { useAuth } from "../../../services/use-auth.js";
import { IconButton, Modal, Switch, Tooltip } from "@mui/material";
import RenderAccountRow from "./render_account_row.jsx";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";
import UserContext from "../../../assets/user_context.jsx";
import {
  RowWrapper,
  LineText,
  TypeCell,
  TypeText,
  GroupCell,
  GroupText,
  StyledTableHead,
  StyledTableRow,
} from "../table_styles.jsx";
import withSubscriptionProtection from "../../../services/with_subscription.jsx";
import AddAccount from "../manage_accounts/add_account.jsx";
import {
  PageWrapper,
  ModalBox,
  ModalInner,
  ClickableDiv,
  HeaderText,
} from "./chart_of_accounts_styles.jsx";
import RearrangeAccountsUI from "./rearrange_accounts_ui.jsx";
import { Backspace, Check, Close, Edit } from "@mui/icons-material";
import { doc, updateDoc } from "firebase/firestore";

/*
 * accountsHierarchy JSON Structure:
 *
 * {
 *   "types": [
 *     {
 *       "type": string,      // The account category (e.g., "Assets", "Liabilities", "Income", "Expenses", "Equity")
 *       "groups": [
 *         {
 *           "groupName": string, // The name of the group within the type (e.g., "Assets", "Bills", "Income", etc.)
 *           "accounts": [
 *             {
 *               "id": string,         // Unique Firebase document ID for the account
 *               // Optional: Some accounts may include a "subAccounts" field.
 *               // If present, "subAccounts" is an array (which can be empty) of sub-account objects:
 *               "subAccounts": [      // (Optional) Array of sub-accounts
 *                 {
 *                   "id": string      // Unique identifier for the sub-account
 *                   // No additional fields like account number or name are stored here
 *                 },
 *                 // ... more sub-accounts
 *               ]
 *             },
 *             // ... more account objects
 *           ]
 *         },
 *         // ... more group objects
 *       ]
 *     },
 *     // ... more type objects
 *   ]
 * }
 */

const ChartOfAccountsProtected = () => {
  const authHook = useAuth();

  const { user, org, accounts, accountsHierarchy, orgRoles, userData } =
    useContext(UserContext);

  //Modal controls for adding a new account
  const [showModal, setShowModal] = useState(false);
  const [accountToAddOptions, setAccountToAddOptions] = useState({
    type: "",
    group: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [accountToEditOptions, setAccountToEditOptions] = useState({
    accountType: "",
    group: "",
    accountNumber: "",
    accountName: "",
    accountDatabaseId: "",
  });

  const [editingName, setEditingName] = useState(null);
  const [newGroupName, setNewGroupName] = useState("");

  const changeGroupName = async (typeBeingEdited) => {
    //Be sure group name does not already exist within this type
    console.log(typeBeingEdited, newGroupName, editingName, accountsHierarchy);
    if (
      accountsHierarchy.types
        .find((typeInArr) => typeInArr.type === typeBeingEdited)
        .groups.some((group) => group.groupName === newGroupName)
    ) {
      alert("The group name must be unique within this account type.");
      return;
    }
    const accountsHierarchyRef = doc(
      authHook.db,
      "orgs",
      org,
      "organizationAndSettings",
      "accountsHierarchy",
    );
    await updateDoc(accountsHierarchyRef, {
      types: accountsHierarchy.types.map((type) => {
        if (type.type === typeBeingEdited) {
          return {
            ...type,
            groups: type.groups.map((group) => {
              if (group.groupName === editingName) {
                return {
                  ...group,
                  groupName: newGroupName,
                };
              }
              return group;
            }),
          };
        }
        return type;
      }),
    });
    setEditingName(null);
    setNewGroupName("");
  };

  return (
    <PageWrapper>
      {!editMode &&
        (orgRoles.some((role) => role === "admin" || role === "bookkeeper") ||
          userData.superAdmin) && (
          <div
            style={{
              position: "absolute",
              top: "2rem",
              right: "2rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}>
            <Switch
              color="secondary"
              checked={editMode}
              onChange={() => setEditMode(!editMode)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <LineText>Rearrange Accounts</LineText>
          </div>
        )}

      {/* Component for Adding a New Account */}
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalBox>
          <ModalInner style={{ maxHeight: "95vh", overflowY: "auto" }}>
            <AddAccount
              db={authHook.db}
              authUser={user}
              org={org}
              setShowModal={setShowModal}
              accounts={accounts}
              accountsHierarchy={accountsHierarchy}
              options={accountToAddOptions}
            />
          </ModalInner>
        </ModalBox>
      </Modal>

      {/* Component for Editing an Account */}
      <Modal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalBox>
          <ModalInner>
            <AddAccount
              db={authHook.db}
              authUser={user}
              org={org}
              setShowModal={setShowEditModal}
              accounts={accounts}
              accountsHierarchy={accountsHierarchy}
              accountDataToEdit={accountToEditOptions}
            />
          </ModalInner>
        </ModalBox>
      </Modal>

      {editMode ? (
        <RearrangeAccountsUI editMode={editMode} setEditMode={setEditMode} />
      ) : (
        <React.Fragment>
          {(orgRoles.some(
            (role) => role === "admin" || role === "bookkeeper",
          ) ||
            userData.superAdmin) && (
            <RowWrapper>
              <ClickableDiv
                onClick={() => {
                  setAccountToAddOptions({
                    type: "",
                    group: "",
                  });
                  setShowModal(true);
                }}>
                <AddCircleOutlineIcon />
                <div style={{ width: "1rem" }} />
                <LineText>Add Account</LineText>
              </ClickableDiv>
            </RowWrapper>
          )}

          {accountsHierarchy && Object.keys(accountsHierarchy).length > 0 ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <HeaderText>Account Number</HeaderText>
                    </TableCell>
                    <TableCell align="left">
                      <HeaderText>Account Name</HeaderText>
                    </TableCell>
                    <TableCell align="center">
                      <HeaderText>Attribute</HeaderText>
                    </TableCell>
                    <TableCell align="center">
                      <HeaderText>Edit</HeaderText>
                    </TableCell>
                    <TableCell align="center">
                      <HeaderText>Enabled</HeaderText>
                    </TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {accountsHierarchy.types
                    .filter((type) => type.type !== "Equity")
                    .map((typeObj) => (
                      <React.Fragment key={`${typeObj.type}-fragment`}>
                        <StyledTableRow key={`${typeObj.type}-row`}>
                          <TypeCell colSpan={5} key={`${typeObj.type}-cell`}>
                            <div
                              style={{ width: "1rem" }}
                              key={`${typeObj.type}-spacer`}
                            />
                            <TypeText>{`${typeObj.type} Accounts`}</TypeText>
                          </TypeCell>
                        </StyledTableRow>
                        {/* Groups Here */}
                        {typeObj.groups.map((groupObj) => (
                          <React.Fragment
                            key={`${groupObj.groupName}-fragment`}>
                            <TableRow key={`${groupObj.groupName}-row`}>
                              <GroupCell
                                colSpan={5}
                                key={`${groupObj.groupName}-cell`}>
                                <div
                                  key={`${groupObj.groupName}-containerDiv`}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}>
                                  <div
                                    style={{ width: "3rem" }}
                                    key={`${groupObj.groupName}-spacerDiv`}
                                  />
                                  {editingName !== groupObj.groupName ? (
                                    <React.Fragment>
                                      {typeObj.type !== "Equity" &&
                                        (orgRoles.some(
                                          (role) =>
                                            role === "admin" ||
                                            role === "bookkeeper",
                                        ) ||
                                          userData.superAdmin) && (
                                          <Tooltip
                                            title={`Add Account to ${groupObj.groupName}`}>
                                            <AddCircleOutlineIcon
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                setAccountToAddOptions({
                                                  type: typeObj.type,
                                                  group: groupObj.groupName,
                                                });
                                                setShowModal(true);
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      <div style={{ width: "1rem" }} />
                                      <GroupText>
                                        {groupObj.groupName}
                                      </GroupText>
                                      <div style={{ width: "1rem" }} />
                                      <Tooltip
                                        title={`Change Name of ${groupObj.groupName}`}>
                                        <Edit
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setEditingName(groupObj.groupName);
                                            setNewGroupName(groupObj.groupName);
                                          }}
                                        />
                                      </Tooltip>
                                    </React.Fragment>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}>
                                      <TextField
                                        label="Edit Name"
                                        value={newGroupName}
                                        onChange={(e) =>
                                          setNewGroupName(e.target.value)
                                        }
                                        InputProps={{
                                          endAdornment: (
                                            <IconButton
                                              onClick={() =>
                                                changeGroupName(typeObj.type)
                                              }>
                                              <Check
                                                style={{ color: "#61b561" }}
                                                fontSize="large"
                                              />
                                            </IconButton>
                                          ),
                                        }}
                                      />
                                      <br />
                                      <Close
                                        onClick={() => {
                                          setEditingName(null);
                                          setNewGroupName("");
                                        }}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </GroupCell>
                            </TableRow>
                            {groupObj.accounts.map((account) => (
                              <React.Fragment key={`${account.id}-fragment`}>
                                <RenderAccountRow
                                  key={`account-details-row-${account.id}}`}
                                  account={account}
                                  accounts={accounts}
                                  typeObj={typeObj}
                                  groupObj={groupObj}
                                  setEditingData={setAccountToEditOptions}
                                  setShowEditModal={setShowEditModal}
                                  child={false}
                                />
                                {account.subAccounts?.length > 0 &&
                                  account.subAccounts.map((subAccount) => (
                                    <RenderAccountRow
                                      key={`account-details-row-${subAccount}}`}
                                      account={subAccount}
                                      accounts={accounts}
                                      typeObj={typeObj}
                                      groupObj={groupObj}
                                      setEditingData={setAccountToEditOptions}
                                      setShowEditModal={setShowEditModal}
                                      parent={account}
                                      child={true}
                                    />
                                  ))}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            //   No Hierarchy Found, just list accounts out
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Account Number</TableCell>
                    <TableCell align="right">Account Name</TableCell>
                    <TableCell align="right">Account Type</TableCell>
                    <TableCell align="right">Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {accounts.map((account) => (
                    <TableRow
                      key={account.accountNumber}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        {account.accountNumber}
                      </TableCell>
                      <TableCell align="right">{account.accountName}</TableCell>
                      <TableCell align="right">{account.accountType}</TableCell>
                      <TableCell align="right">{account.balance}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </React.Fragment>
      )}
    </PageWrapper>
  );
};

const ChartOfAccounts = withSubscriptionProtection(ChartOfAccountsProtected);

export default ChartOfAccounts;
