import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { useAuth } from "../../../services/use-auth.js";
import { getFunctions, httpsCallable } from "firebase/functions";
import AccountBudgetRow from "./AccountBudgetRow.jsx";

// Helper: Returns an ordered array of month codes based on financialYearStart.
const getOrderedMonths = (financialYearStart) => {
  const allMonths = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  const startIndex = allMonths.indexOf(financialYearStart.toLowerCase());
  return startIndex === -1
    ? allMonths
    : [...allMonths.slice(startIndex), ...allMonths.slice(0, startIndex)];
};

// Simple debounce hook.
const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);
  const debouncedCallback = (...args) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => callback(...args), delay);
  };
  return debouncedCallback;
};

const CreateEditBudget = ({
  selectedYear,
  initialBudgetDoc,
  orgData,
  org,
  editable,
}) => {
  const authHook = useAuth();
  const monthOrder = getOrderedMonths(orgData.financialYearStart || "jan");
  const accounts = initialBudgetDoc.accounts || [];
  const accountsHierarchy = initialBudgetDoc.accountsHierarchy || {};

  // Local state for budgets, history, and change counter.
  const [budgets, setBudgets] = useState(initialBudgetDoc.budgets || {});
  const [history, setHistory] = useState([]);
  const [changeCount, setChangeCount] = useState(
    initialBudgetDoc.changeCount || 0,
  );

  // States for historical data.
  const [priorIncomeData, setPriorIncomeData] = useState(null);
  const [loadingPriorIncomeData, setLoadingPriorIncomeData] = useState(false);
  const [lastYearBudget, setLastYearBudget] = useState(null);
  const [loadingLastYearBudget, setLoadingLastYearBudget] = useState(false);

  const defaultBudget = {
    total: 0,
    months: monthOrder.reduce((acc, m) => ({ ...acc, [m]: 0 }), {}),
    byFund: {},
    byLabel: {},
  };

  // Debounced update to Firestore (updates budgets and changeCount).
  const debouncedFirestoreUpdate = useDebounce(
    async (updatedBudgets, updatedChangeCount) => {
      try {
        const budgetRef = doc(
          authHook.db,
          "orgs",
          org,
          "budgets",
          initialBudgetDoc.id,
        );
        await updateDoc(budgetRef, {
          budgets: updatedBudgets,
          changeCount: updatedChangeCount,
        });
        console.log("Budget updated in Firestore.");
      } catch (err) {
        console.error("Error updating budget:", err);
      }
    },
    1000,
  );

  // Update a given account's budget.
  const handleBudgetChange = (accountId, updatedBudget) => {
    setHistory((prev) => [...prev, budgets]);
    const newBudgets = { ...budgets, [accountId]: updatedBudget };
    setBudgets(newBudgets);
    setChangeCount((prev) => {
      const newCount = prev + 1;
      debouncedFirestoreUpdate(newBudgets, newCount);
      return newCount;
    });
  };

  // Undo the last change.
  const handleUndo = () => {
    if (history.length > 0) {
      const previous = history[history.length - 1];
      setHistory((prev) => prev.slice(0, prev.length - 1));
      setBudgets(previous);
      setChangeCount((prev) => {
        const newCount = prev + 1;
        debouncedFirestoreUpdate(previous, newCount);
        return newCount;
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "z") {
        e.preventDefault();
        handleUndo();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [history]);

  const computeTotals = () => {
    let incomeTotal = 0;
    let expenseTotal = 0;
    accounts.forEach((account) => {
      const acctBudget = budgets[account.id];
      if (acctBudget) {
        if (account.accountType === "Income") incomeTotal += acctBudget.total;
        else if (account.accountType === "Expenses")
          expenseTotal += acctBudget.total;
      }
    });
    return { incomeTotal, expenseTotal, netTotal: incomeTotal - expenseTotal };
  };

  const totals = computeTotals();

  // Fetch last year's actual data.
  useEffect(() => {
    if (!selectedYear) return;
    const fetchPriorIncomeData = async () => {
      setLoadingPriorIncomeData(true);
      try {
        const functions = getFunctions();
        const getPriorYearIncomeStatement = httpsCallable(
          functions,
          "getPriorYearIncomeStatement",
        );
        const result = await getPriorYearIncomeStatement({
          orgId: org,
          budgetYear: selectedYear,
          financialYearStart: orgData.financialYearStart,
          accounts: accounts,
        });
        setPriorIncomeData(result.data);
      } catch (err) {
        console.error("Error fetching prior income data:", err);
        setPriorIncomeData(null);
      }
      setLoadingPriorIncomeData(false);
    };
    fetchPriorIncomeData();
  }, [selectedYear, org, orgData.financialYearStart, accounts]);

  // Fetch last year's budget.
  useEffect(() => {
    if (!selectedYear) return;
    const fetchLastYearBudget = async () => {
      setLoadingLastYearBudget(true);
      const lastYear = (parseInt(selectedYear, 10) - 1).toString();
      const draftId = `${lastYear}-draft`;
      const publishedId = lastYear;
      try {
        let budgetDoc = null;
        const draftRef = doc(authHook.db, "orgs", org, "budgets", draftId);
        const draftSnap = await getDoc(draftRef);
        if (draftSnap.exists()) {
          budgetDoc = { id: draftId, ...draftSnap.data() };
        } else {
          const publishedRef = doc(
            authHook.db,
            "orgs",
            org,
            "budgets",
            publishedId,
          );
          const publishedSnap = await getDoc(publishedRef);
          if (publishedSnap.exists()) {
            budgetDoc = { id: publishedId, ...publishedSnap.data() };
          }
        }
        setLastYearBudget(budgetDoc);
      } catch (err) {
        console.error("Error fetching last year's budget:", err);
        setLastYearBudget(null);
      }
      setLoadingLastYearBudget(false);
    };
    fetchLastYearBudget();
  }, [selectedYear, authHook.db, org]);

  // Handlers to use historical data.
  const handleUseLastYearActual = () => {
    if (!priorIncomeData || !priorIncomeData.accounts) return;
    const priorDataMap = {};
    priorIncomeData.accounts.forEach((acct) => {
      priorDataMap[acct.id] = acct;
    });
    const newBudgets = { ...budgets };
    Object.keys(newBudgets).forEach((acctId) => {
      if (priorDataMap[acctId]) {
        newBudgets[acctId] = {
          total: priorDataMap[acctId].total,
          months: priorDataMap[acctId].monthlyTotals,
          byFund: {},
          byLabel: {},
        };
      }
    });
    setBudgets(newBudgets);
    setChangeCount((prev) => {
      const newCount = prev + 1;
      debouncedFirestoreUpdate(newBudgets, newCount);
      return newCount;
    });
  };

  const handleUseLastYearBudget = () => {
    if (!lastYearBudget || !lastYearBudget.budgets) return;
    setBudgets(lastYearBudget.budgets);
    setChangeCount((prev) => {
      const newCount = prev + 1;
      debouncedFirestoreUpdate(lastYearBudget.budgets, newCount);
      return newCount;
    });
  };

  const priorDataMap =
    priorIncomeData && priorIncomeData.accounts
      ? Object.fromEntries(
          priorIncomeData.accounts.map((acct) => [acct.id, acct]),
        )
      : {};

  return (
    <Paper style={{ padding: "1rem" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "1rem",
        }}>
        {/* Action Buttons for historical data */}
        <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
          <Tooltip
            title={
              loadingPriorIncomeData
                ? "Loading historical data..."
                : !priorIncomeData ||
                    (priorIncomeData.missingMonths &&
                      priorIncomeData.missingMonths.length > 0)
                  ? "Incomplete data from last year"
                  : !editable
                    ? "Draft is not editable"
                    : ""
            }>
            <span>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUseLastYearActual}
                disabled={
                  loadingPriorIncomeData ||
                  !priorIncomeData ||
                  (priorIncomeData.missingMonths &&
                    priorIncomeData.missingMonths.length > 0) ||
                  !editable
                }>
                Use Last Year's Actual
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              loadingLastYearBudget
                ? "Loading last year's budget..."
                : !lastYearBudget
                  ? "Last year's budget not available"
                  : !editable
                    ? "Draft is not editable"
                    : ""
            }>
            <span>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUseLastYearBudget}
                disabled={
                  loadingLastYearBudget || !lastYearBudget || !editable
                }>
                Use Last Year's Budget
              </Button>
            </span>
          </Tooltip>
        </div>

        {editable && (
          <Typography
            variant="h5"
            style={{ marginBottom: "1rem", color: "grey" }}>
            Draft with ({changeCount} saved changes)
          </Typography>
        )}

        {/* Totals Header */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
            marginBottom: "1rem",
            gap: "1rem",
          }}>
          <div style={{ textAlign: "center" }}>
            <Typography variant="caption" color="textSecondary">
              Income
            </Typography>
            <Typography variant="h6">
              {`$${(totals.incomeTotal / 100).toLocaleString()}`}
            </Typography>
          </div>
          <div
            style={{
              textAlign: "center",
              alignSelf: "flex-end",
              marginBottom: "0.5rem",
              height: "2rem",
            }}>
            <Typography variant="h4">-</Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography variant="caption" color="textSecondary">
              Expenses
            </Typography>
            <Typography variant="h6">
              {`$${(totals.expenseTotal / 100).toLocaleString()}`}
            </Typography>
          </div>
          <div
            style={{
              textAlign: "center",
              alignSelf: "flex-end",
              marginBottom: "0.5rem",
              height: "1.5rem",
            }}>
            <Typography variant="h5">=</Typography>
          </div>
          <div style={{ textAlign: "center" }}>
            <Typography variant="caption" color="textSecondary">
              Net
            </Typography>
            <Typography variant="h6">
              {`$${(totals.netTotal / 100).toLocaleString()}`}
            </Typography>
          </div>
        </div>
      </div>
      {/* Budget Table */}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell>Annual</TableCell>
            <TableCell>Actions</TableCell>
            {monthOrder.map((month) => (
              <TableCell key={`header-${month}`}>
                {month.toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts &&
            accountsHierarchy &&
            accountsHierarchy.types &&
            accountsHierarchy.types
              .filter((t) => t.type === "Income" || t.type === "Expenses")
              .map((typeObj) => {
                let typeTotal = 0;
                typeObj.groups.forEach((group) => {
                  group.accounts.forEach((acctRef) => {
                    typeTotal +=
                      (budgets[acctRef.id]?.total || 0) +
                      (acctRef.subAccounts
                        ? acctRef.subAccounts.reduce(
                            (sum, subId) => sum + (budgets[subId]?.total || 0),
                            0,
                          )
                        : 0);
                  });
                });
                return (
                  <React.Fragment key={typeObj.type}>
                    <TableRow style={{ backgroundColor: "#f0f0f0" }}>
                      <TableCell colSpan={monthOrder.length + 3}>
                        <strong>{typeObj.type}</strong> Total: $
                        {(typeTotal / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </TableCell>
                    </TableRow>
                    {typeObj.groups.map((group) => {
                      const showGroupTotal = typeObj.groups.length > 1;
                      let groupTotal = 0;
                      group.accounts.forEach((acctRef) => {
                        groupTotal +=
                          (budgets[acctRef.id]?.total || 0) +
                          (acctRef.subAccounts
                            ? acctRef.subAccounts.reduce(
                                (sum, subId) =>
                                  sum + (budgets[subId]?.total || 0),
                                0,
                              )
                            : 0);
                      });
                      return (
                        <React.Fragment key={group.groupName}>
                          {showGroupTotal && (
                            <TableRow style={{ backgroundColor: "#e0e0e0" }}>
                              <TableCell
                                colSpan={
                                  monthOrder.length + (editable ? 3 : 2)
                                }>
                                <strong>{group.groupName}</strong> Group Total:
                                $
                                {(groupTotal / 100).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </TableCell>
                            </TableRow>
                          )}
                          {group.accounts.map((acctRef) => {
                            const account = accounts.find(
                              (a) => a.id === acctRef.id,
                            );
                            if (!account) return null;
                            return (
                              <React.Fragment key={account.id}>
                                <AccountBudgetRow
                                  account={account}
                                  initialBudget={
                                    budgets[account.id] || defaultBudget
                                  }
                                  monthOrder={monthOrder}
                                  onBudgetChange={
                                    editable ? handleBudgetChange : undefined
                                  }
                                  dense={true}
                                  child={false}
                                  priorData={priorDataMap[account.id] || null}
                                  editable={editable}
                                />
                                {acctRef.subAccounts &&
                                  acctRef.subAccounts.length > 0 && (
                                    <>
                                      {acctRef.subAccounts.map((subId) => {
                                        const subAccount = accounts.find(
                                          (a) => a.id === subId,
                                        );
                                        if (!subAccount) return null;
                                        return (
                                          <AccountBudgetRow
                                            key={subAccount.id}
                                            account={subAccount}
                                            initialBudget={
                                              budgets[subAccount.id] ||
                                              defaultBudget
                                            }
                                            monthOrder={monthOrder}
                                            onBudgetChange={
                                              editable
                                                ? handleBudgetChange
                                                : undefined
                                            }
                                            dense={true}
                                            child={true}
                                            priorData={
                                              priorDataMap[subAccount.id] ||
                                              null
                                            }
                                            editable={editable}
                                          />
                                        );
                                      })}
                                      <TableRow>
                                        <TableCell>
                                          <strong>
                                            Subtotal for {account.accountName}
                                          </strong>
                                        </TableCell>
                                        <TableCell>
                                          $
                                          {(
                                            ((budgets[account.id]?.total || 0) +
                                              acctRef.subAccounts.reduce(
                                                (sum, subId) =>
                                                  sum +
                                                  (budgets[subId]?.total || 0),
                                                0,
                                              )) /
                                            100
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })}
                                        </TableCell>
                                        {editable && <TableCell></TableCell>}
                                        {monthOrder.map((month) => {
                                          const parentMonth =
                                            budgets[account.id]?.months?.[
                                              month
                                            ] || 0;
                                          const subMonths =
                                            acctRef.subAccounts.reduce(
                                              (sum, subId) =>
                                                sum +
                                                (budgets[subId]?.months?.[
                                                  month
                                                ] || 0),
                                              0,
                                            );
                                          const totalMonth =
                                            parentMonth + subMonths;
                                          return (
                                            <TableCell
                                              key={`${account.id}-subtotal-${month}`}>
                                              $
                                              {(
                                                totalMonth / 100
                                              ).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    </>
                                  )}
                              </React.Fragment>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default CreateEditBudget;
