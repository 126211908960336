// /reports/account_type_rows.jsx
import React from "react";
import styled from "@emotion/styled";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  ClickableLineText,
  CollapsableTableItem,
  LargeLineText,
  LineText,
  LineTextEmphasized,
} from "./reports_styles";
import { primary_dark_color_lighter } from "../../constants/color_constants";

const GroupItem = styled("td")`
  cursor: pointer;
  display: flex; /* This sets the content to be in a row */
  justify-content: space-between;
  align-items: flex-start; /* Adjust as needed to vertically center the content */
  background-color: #f0f0f0;
  border-radius: 10px 0 0 10px;
  height: 100%;
`;

const ParentAccountItem = styled("td")`
  cursor: pointer;
  display: flex; /* This sets the content to be in a row */
  justify-content: space-between;
  border-radius: 5px;
`;

const FundsTotalItem = styled("td")`
  cursor: pointer;
  border-radius: 0 10px 10px 0;
  background-color: ${(props) => (props.shade ? "#f0f0f0" : "white")};
`;

const Row = styled("tr")`
  height: auto; /* Ensures the row height adjusts properly */
`;

const formatCurrencyUSD = (num, withCents = false) => {
  const float = num / 100;
  const options = withCents
    ? { style: "currency", currency: "USD" }
    : {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      };

  return new Intl.NumberFormat("en-us", options).format(float);
};

const drillDown = ({ relevantLines, filterTransactionsToInspect }) => {
  filterTransactionsToInspect({ relevantLines });
};

export const EquityColumns = ({
  balanceSheetObject,
  fundsDetailsToDisplay,
  showFunds,
  withCents, // added parameter
}) => {
  console.log("balanceSheetObject", balanceSheetObject);
  const total = true;
  const columns = balanceSheetObject.find(
    (type) => type.accountTypeName === "Equity",
  ).columns;
  return (
    <React.Fragment>
      {!showFunds && (
        <Row>
          <LargeLineText>Equity Accounts</LargeLineText>
        </Row>
      )}
      {!showFunds &&
        columns
          .filter((_col, index) => index !== columns.length - 1)
          .map((group, groupIndex) => {
            return group.funds.map((fund, fundIndex) => {
              return (
                <React.Fragment
                  key={`${fund.fundNumber}-equity-${groupIndex}-${fundIndex}`}>
                  <Row>
                    <td>
                      <LineText>{fund.fundNumber}</LineText>
                    </td>
                    <td>
                      <LineText style={{ textAlign: "right" }}>
                        {formatCurrencyUSD(fund.total, withCents)}
                      </LineText>
                    </td>
                  </Row>
                  {fund.subFunds?.map((subFund, subFundIndex) => {
                    return (
                      <Row
                        key={`${fund.fundNumber}-equity-${groupIndex}-${fundIndex}-${subFundIndex}`}>
                        <td>
                          <LineText>{subFund.fundNumber}</LineText>
                        </td>
                        <td>
                          <LineText style={{ textAlign: "right" }}>
                            {formatCurrencyUSD(subFund.total, withCents)}
                          </LineText>
                        </td>
                      </Row>
                    );
                  })}
                </React.Fragment>
              );
            });
          })}
      {!showFunds && (
        <Row>
          <td>
            <LineTextEmphasized>Total Equity</LineTextEmphasized>
          </td>
          <td>
            <LineText style={{ textAlign: "right" }}>
              {formatCurrencyUSD(columns[columns.length - 1].total, withCents)}
            </LineText>
          </td>
        </Row>
      )}
      <Row>
        {showFunds && <LargeLineText>Equity Accounts</LargeLineText>}
        {showFunds &&
          columns
            .filter(
              // remove the last column, since it's just a total
              (column, index) => index < columns.length - 1,
            )
            .map((fundGroup, fundGroupIndex) =>
              fundsDetailsToDisplay[fundGroupIndex].show ? (
                fundGroup.funds.map((fund, fundIndex) =>
                  fundsDetailsToDisplay[fundGroupIndex].funds[fundIndex]
                    .show ? (
                    <React.Fragment
                      key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}`}>
                      <CollapsableTableItem total={total}>
                        <ClickableLineText
                          onClick={() => drillDown(fund)}
                          total={total}
                          style={{
                            textAlign: "right",
                            marginTop: "0.25rem",
                            marginBottom: "0.25rem",
                            marginRight: "0.5rem",
                          }}>
                          {formatCurrencyUSD(fund.total, withCents)}
                        </ClickableLineText>
                      </CollapsableTableItem>
                      {fund.subFunds?.length > 0 &&
                        fund.subFunds.map((subFund, subFundIndex) => (
                          <CollapsableTableItem
                            total={total}
                            key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}-${subFundIndex}`}>
                            <ClickableLineText
                              total={total}
                              style={{
                                textAlign: "right",
                                marginTop: "0.25rem",
                                marginBottom: "0.25rem",
                                marginRight: "0.5rem",
                              }}>
                              {formatCurrencyUSD(subFund.total, withCents)}
                            </ClickableLineText>
                          </CollapsableTableItem>
                        ))}
                    </React.Fragment>
                  ) : (
                    <CollapsableTableItem
                      total={total}
                      key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}`}>
                      <ClickableLineText
                        onClick={() => drillDown(fund)}
                        total={total}
                        style={{
                          textAlign: "right",
                          marginTop: "0.25rem",
                          marginBottom: "0.25rem",
                          marginRight: "0.5rem",
                        }}>
                        {formatCurrencyUSD(fund.withSubFundsTotal, withCents)}
                      </ClickableLineText>
                    </CollapsableTableItem>
                  ),
                )
              ) : (
                <CollapsableTableItem
                  total={total}
                  style={{ borderRadius: "0 1rem 1rem 0" }}
                  key={`${fundGroup.fundGroupName}-equity`}>
                  <ClickableLineText
                    onClick={() => drillDown(fundGroup)}
                    total={total}
                    style={{
                      textAlign: "right",
                      marginTop: "0.25rem",
                      marginBottom: "0.25rem",
                      marginRight: "0.5rem",
                    }}>
                    {formatCurrencyUSD(fundGroup.total, withCents)}
                  </ClickableLineText>
                </CollapsableTableItem>
              ),
            )}
        {showFunds && (
          <FundsTotalItem>
            <LineText style={{ textAlign: "right" }}>
              {formatCurrencyUSD(columns[columns.length - 1].total, withCents)}
            </LineText>
          </FundsTotalItem>
        )}
      </Row>
    </React.Fragment>
  );
};

export const NetIncomeColumns = ({
  incomeStatementData,
  fundsDetailsToDisplay,
  showFunds,
  withCents, // added parameter
}) => {
  const total = true;
  const columns = incomeStatementData.find(
    (type) => type.accountTypeName === "Net Income",
  ).columns;

  console.log(incomeStatementData);
  return (
    <React.Fragment>
      <Row>
        {showFunds && <LargeLineText>Net Income</LargeLineText>}
        {showFunds &&
          columns
            .filter(
              // remove the last column, since it's just a total
              (column, index) => index < columns.length - 1,
            )
            .map((fundGroup, fundGroupIndex) =>
              fundsDetailsToDisplay[fundGroupIndex].show ? (
                fundGroup.funds.map((fund, fundIndex) =>
                  fundsDetailsToDisplay[fundGroupIndex].funds[fundIndex]
                    .show ? (
                    <React.Fragment
                      key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}`}>
                      <CollapsableTableItem total={total}>
                        <ClickableLineText
                          onClick={() => drillDown(fund)}
                          total={total}
                          style={{
                            textAlign: "right",
                            marginTop: "0.25rem",
                            marginBottom: "0.25rem",
                            marginRight: "0.5rem",
                          }}>
                          {formatCurrencyUSD(fund.total, withCents)}
                        </ClickableLineText>
                      </CollapsableTableItem>
                      {fund.subFunds?.length > 0 &&
                        fund.subFunds.map((subFund, subFundIndex) => (
                          <CollapsableTableItem
                            total={total}
                            key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}-${subFundIndex}`}>
                            <ClickableLineText
                              onClick={() => drillDown(subFund)}
                              total={total}
                              style={{
                                textAlign: "right",
                                marginTop: "0.25rem",
                                marginBottom: "0.25rem",
                                marginRight: "0.5rem",
                              }}>
                              {formatCurrencyUSD(subFund.total, withCents)}
                            </ClickableLineText>
                          </CollapsableTableItem>
                        ))}
                    </React.Fragment>
                  ) : (
                    <CollapsableTableItem
                      total={total}
                      key={`${fund.fundNumber}-equity-${fundGroupIndex}-${fundIndex}`}>
                      <ClickableLineText
                        onClick={() => drillDown(fund)}
                        total={total}
                        style={{
                          textAlign: "right",
                          marginTop: "0.25rem",
                          marginBottom: "0.25rem",
                          marginRight: "0.5rem",
                        }}>
                        {formatCurrencyUSD(fund.withSubFundsTotal, withCents)}
                      </ClickableLineText>
                    </CollapsableTableItem>
                  ),
                )
              ) : (
                <CollapsableTableItem
                  total={total}
                  style={{ borderRadius: "0 1rem 1rem 0" }}
                  key={`${fundGroup.fundGroupName}-equity`}>
                  <ClickableLineText
                    onClick={() => drillDown(fundGroup)}
                    total={total}
                    style={{
                      textAlign: "right",
                      marginTop: "0.25rem",
                      marginBottom: "0.25rem",
                      marginRight: "0.5rem",
                    }}>
                    {formatCurrencyUSD(fundGroup.total, withCents)}
                  </ClickableLineText>
                </CollapsableTableItem>
              ),
            )}
        {showFunds && (
          <FundsTotalItem>
            <LineText style={{ textAlign: "right" }}>
              {formatCurrencyUSD(columns[columns.length - 1].total, withCents)}
            </LineText>
          </FundsTotalItem>
        )}
      </Row>
    </React.Fragment>
  );
};

export const FundSummaryColumns = ({
  fundSummary,
  fundsHierarchy,
  fundsDetailsToDisplay,
  withCents,
}) => {
  if (!fundSummary || !fundsHierarchy || !fundsDetailsToDisplay) return null;

  // Format numbers as currency, with or without cents
  const formatCurrency = (num) => {
    const float = num / 100;
    const options = withCents
      ? {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      : {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        };
    return new Intl.NumberFormat("en-us", options).format(float);
  };

  // Calculate the overall total across all funds
  const overallTotal = Object.values(fundSummary.columns).reduce(
    (sum, value) => sum + value,
    0,
  );

  return (
    <Row>
      <td>
        <LineTextEmphasized>{fundSummary.accountTypeName}</LineTextEmphasized>
      </td>
      {fundsHierarchy.groups.map((group, groupIndex) => {
        // Check if the entire group is collapsed or expanded.
        const groupIsVisible = fundsDetailsToDisplay[groupIndex]?.show;
        if (!groupIsVisible) {
          // If the group is collapsed, sum the parent's and all sub fund values.
          const groupTotal = group.funds.reduce((acc, fund) => {
            const parentVal = fundSummary.columns[fund.id] || 0;
            const subVal = fund.subFunds
              ? fund.subFunds.reduce(
                  (s, subFund) => s + (fundSummary.columns[subFund.id] || 0),
                  0,
                )
              : 0;
            return acc + parentVal + subVal;
          }, 0);
          return (
            <CollapsableTableItem total key={`group-${groupIndex}`}>
              <ClickableLineText
                onClick={() => drillDown(group)}
                total
                style={{
                  textAlign: "right",
                  marginTop: "0.25rem",
                  marginBottom: "0.25rem",
                  marginRight: "0.5rem",
                }}>
                {formatCurrency(groupTotal)}
              </ClickableLineText>
            </CollapsableTableItem>
          );
        } else {
          // When expanded, iterate over each fund in the group.
          return group.funds.map((fund, fundIndex) => {
            // Check if this individual fund is set to show.
            const fundIsVisible =
              fundsDetailsToDisplay[groupIndex].funds[fundIndex]?.show;
            if (!fundIsVisible) return null;
            return (
              <React.Fragment key={`group-${groupIndex}-fund-${fundIndex}`}>
                <CollapsableTableItem total>
                  <ClickableLineText
                    onClick={() => drillDown(fund)}
                    total
                    style={{
                      textAlign: "right",
                      marginTop: "0.25rem",
                      marginBottom: "0.25rem",
                      marginRight: "0.5rem",
                    }}>
                    {formatCurrency(fundSummary.columns[fund.id] || 0)}
                  </ClickableLineText>
                </CollapsableTableItem>
                {fund.subFunds &&
                  fund.subFunds.length > 0 &&
                  fund.subFunds.map((subFund, subFundIndex) => (
                    <CollapsableTableItem
                      total
                      key={`group-${groupIndex}-fund-${fundIndex}-sub-${subFundIndex}`}>
                      <ClickableLineText
                        onClick={() => drillDown(subFund)}
                        total
                        style={{
                          textAlign: "right",
                          marginTop: "0.25rem",
                          marginBottom: "0.25rem",
                          marginRight: "0.5rem",
                        }}>
                        {formatCurrency(fundSummary.columns[subFund.id] || 0)}
                      </ClickableLineText>
                    </CollapsableTableItem>
                  ))}
              </React.Fragment>
            );
          });
        }
      })}
      <FundsTotalItem>
        <ClickableLineText
          total
          style={{
            textAlign: "right",
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
            marginRight: "0.5rem",
          }}>
          {formatCurrency(overallTotal)}
        </ClickableLineText>
      </FundsTotalItem>
    </Row>
  );
};

const AccountTypeRows = ({
  accountType,
  balanceSheetObject,
  fundsDetailsToDisplay,
  showFunds,
  filterTransactionsToInspect,
  withCents,
  accountCollapseState,
  setAccountCollapseState,
}) => {
  const AccountColumnsComponent = ({
    columns,
    accountIndex,
    shade,
    total,
    withCents,
  }) => {
    return (
      <React.Fragment>
        {showFunds &&
          columns
            .filter(
              // remove the last column, since it's just a total
              (column, index) => index < columns.length - 1,
            )
            .map((fundGroup, fundGroupIndex) =>
              fundsDetailsToDisplay[fundGroupIndex].show ? (
                fundGroup.funds.map((fund, fundIndex) =>
                  fundsDetailsToDisplay[fundGroupIndex].funds[fundIndex]
                    .show ? (
                    <React.Fragment
                      key={`${fund.fundNumber}-${accountIndex}-${fundGroupIndex}-${fundIndex}`}>
                      <CollapsableTableItem
                        total={total}
                        shouldShow={showFunds}
                        shade={shade}>
                        <ClickableLineText
                          onClick={() =>
                            drillDown({
                              relevantLines: fund.relevantLines,
                              filterTransactionsToInspect,
                            })
                          }
                          total={total}
                          style={{
                            textAlign: "right",
                            marginTop: "0.25rem",
                            marginBottom: "0.25rem",
                            marginRight: "0.5rem",
                          }}>
                          {formatCurrencyUSD(fund.total, withCents)}
                        </ClickableLineText>
                      </CollapsableTableItem>
                      {fund.subFunds?.length > 0 &&
                        fund.subFunds.map((subFund, subFundIndex) => (
                          <CollapsableTableItem
                            total={total}
                            shade={shade}
                            shouldShow={showFunds}
                            key={`${fund.fundNumber}-${accountIndex}-${fundGroupIndex}-${fundIndex}-${subFundIndex}`}>
                            <ClickableLineText
                              onClick={() =>
                                drillDown({
                                  relevantLines: subFund.relevantLines,
                                  filterTransactionsToInspect,
                                })
                              }
                              total={total}
                              style={{
                                textAlign: "right",
                                marginTop: "0.25rem",
                                marginBottom: "0.25rem",
                                marginRight: "0.5rem",
                              }}>
                              {formatCurrencyUSD(subFund.total, withCents)}
                            </ClickableLineText>
                          </CollapsableTableItem>
                        ))}
                    </React.Fragment>
                  ) : (
                    <CollapsableTableItem
                      total={total}
                      shade={shade}
                      shouldShow={showFunds}
                      key={`${fund.fundNumber}-${accountIndex}-${fundGroupIndex}-${fundIndex}`}>
                      <ClickableLineText
                        onClick={() =>
                          drillDown({
                            relevantLines: fund.relevantParentLines,
                            filterTransactionsToInspect,
                          })
                        }
                        total={total}
                        style={{
                          textAlign: "right",
                          marginTop: "0.25rem",
                          marginBottom: "0.25rem",
                          marginRight: "0.5rem",
                        }}>
                        {formatCurrencyUSD(fund.withSubFundsTotal, withCents)}
                      </ClickableLineText>
                    </CollapsableTableItem>
                  ),
                )
              ) : (
                <CollapsableTableItem
                  shade={shade}
                  total={total}
                  shouldShow={showFunds}
                  key={`${fundGroup.fundGroupName}-${accountIndex}`}>
                  <ClickableLineText
                    onClick={() =>
                      drillDown({
                        relevantLines: fundGroup.relevantLines,
                        filterTransactionsToInspect,
                      })
                    }
                    total={total}
                    style={{
                      textAlign: "right",
                      marginTop: "0.25rem",
                      marginBottom: "0.25rem",
                      marginRight: "0.5rem",
                    }}>
                    {formatCurrencyUSD(fundGroup.total, withCents)}
                  </ClickableLineText>
                </CollapsableTableItem>
              ),
            )}
        <FundsTotalItem shade={shade}>
          <LineText
            style={{
              textAlign: "right",
              marginTop: "0.25rem",
              marginBottom: "0.25rem",
              marginRight: "0.5rem",
            }}>
            {formatCurrencyUSD(columns[columns.length - 1].total, withCents)}
          </LineText>
        </FundsTotalItem>
      </React.Fragment>
    );
  };

  // Removed local state for sub account collapse and use the lifted state instead.
  const AccountAllRows = ({ account, accountIndex }) => {
    // Use the lifted state for sub-accounts:
    const isCollapsed =
      accountCollapseState?.accounts[account.accountNumber] || false;
    const toggleSubAccounts = () => {
      setAccountCollapseState((prev) => ({
        ...prev,
        accounts: {
          ...prev.accounts,
          [account.accountNumber]: !isCollapsed,
        },
      }));
    };

    const ParentAccountRow = ({ account }) => {
      return (
        <Row>
          <ParentAccountItem onClick={toggleSubAccounts}>
            <LineText>{`${account.accountNumber}-${account.accountName}`}</LineText>
            {isCollapsed ? (
              <ExpandLess style={{ color: primary_dark_color_lighter }} />
            ) : (
              <ExpandMore style={{ color: primary_dark_color_lighter }} />
            )}
          </ParentAccountItem>
          {isCollapsed ? (
            <AccountColumnsComponent
              accountIndex={accountIndex}
              columns={account.totalRowColumns}
              withCents={withCents}
            />
          ) : (
            <AccountColumnsComponent
              accountIndex={accountIndex}
              columns={account.columns}
              withCents={withCents}
            />
          )}
        </Row>
      );
    };
    const SubAccountRow = ({ account, subAccountIndex }) => {
      return (
        <Row key={`${accountIndex}-${subAccountIndex}`}>
          <td>
            <LineText
              style={{
                paddingLeft: "8%",
              }}>{`${account.accountNumber}-${account.accountName}`}</LineText>
          </td>
          <AccountColumnsComponent
            accountIndex={accountIndex}
            columns={account.columns}
            withCents={withCents}
          />
        </Row>
      );
    };
    const AccountRow = ({ account }) => {
      return (
        <Row>
          <td>
            <LineText>{`${account.accountNumber}-${account.accountName}`}</LineText>
          </td>
          <AccountColumnsComponent
            accountIndex={accountIndex}
            columns={account.columns}
            withCents={withCents}
          />
        </Row>
      );
    };
    const hasSubAccounts = account.subAccounts?.length > 0;
    return (
      <React.Fragment>
        {hasSubAccounts ? (
          <React.Fragment>
            <ParentAccountRow account={account} accountIndex={accountIndex} />
            {!isCollapsed &&
              account.subAccounts.map((subAccount, subAccountIndex) => (
                <SubAccountRow
                  key={`${subAccount.accountNumber}-${accountIndex}${subAccountIndex}`}
                  account={subAccount}
                  subAccountIndex={`${accountIndex}${subAccountIndex}`}
                />
              ))}
          </React.Fragment>
        ) : (
          <AccountRow account={account} accountIndex={accountIndex} />
        )}
      </React.Fragment>
    );
  };

  const GroupRows = ({ group, groupIndex, numberOfGroups }) => {
    // Use the lifted state for groups:
    const isGroupCollapsed = accountCollapseState?.groups[groupIndex] || false;
    const toggleGroupCollapse = () => {
      setAccountCollapseState((prev) => ({
        ...prev,
        groups: {
          ...prev.groups,
          [groupIndex]: !isGroupCollapsed,
        },
      }));
    };
    const groupTotalColumns = group.columns;
    const groupAccountRows = group.accounts;
    return (
      <React.Fragment>
        {numberOfGroups > 1 && (
          <Row>
            <GroupItem onClick={toggleGroupCollapse}>
              <LineText>{group.accountGroupName}</LineText>
              {isGroupCollapsed ? (
                <ExpandLess style={{ color: primary_dark_color_lighter }} />
              ) : (
                <ExpandMore style={{ color: primary_dark_color_lighter }} />
              )}
            </GroupItem>
            {isGroupCollapsed ? (
              <AccountColumnsComponent
                accountIndex={groupIndex}
                columns={groupTotalColumns}
                withCents={withCents}
              />
            ) : (
              <AccountColumnsComponent
                shade={true}
                accountIndex={groupIndex}
                columns={groupTotalColumns}
                withCents={withCents}
              />
            )}
          </Row>
        )}
        {!isGroupCollapsed &&
          groupAccountRows.map((account, accountIndex) => (
            <AccountAllRows
              key={`${account.accountName}-${account.accountNumber}`}
              account={account}
              accountIndex={`${groupIndex}${accountIndex}`}
            />
          ))}
      </React.Fragment>
    );
  };

  const groupsInType = balanceSheetObject.find(
    (groups) =>
      groups.accountTypeName.toLowerCase() === accountType.toLowerCase(),
  );
  if (!groupsInType) {
    return null;
  } else {
    console.log(balanceSheetObject, groupsInType);
    const typeTotalColumns = groupsInType.columns;
    const numberOfGroups = groupsInType.groups.length;

    return (
      <React.Fragment>
        <Row>
          <td>
            <LargeLineText>{accountType}</LargeLineText>
          </td>
        </Row>
        {groupsInType.groups.map((group, groupIndex) => (
          <GroupRows
            key={`${group.groupName}-${groupIndex}`}
            group={group}
            groupIndex={groupIndex}
            numberOfGroups={numberOfGroups}
          />
        ))}
        <Row>
          <td>
            <LineTextEmphasized>{`Total ${accountType}`}</LineTextEmphasized>
          </td>
          <AccountColumnsComponent
            accountIndex={accountType}
            key={`Total ${accountType}`}
            columns={typeTotalColumns}
            total={true}
            withCents={withCents}
          />
        </Row>
      </React.Fragment>
    );
  }
};

export default AccountTypeRows;
