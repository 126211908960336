// /utilities/reusable_input_components.jsx
import React from "react";
import { FormControl, InputAdornment, TextField } from "@mui/material";
import { NumericFormat } from "react-number-format";

/**
 * NumberFormatCustom is a wrapper around react-number-format's NumericFormat.
 * It formats the input as currency with thousand separators and two-decimal precision.
 * It preserves caret position and allows only numbers, ".", and "-" with up to two decimals.
 */
const NumberFormatCustom = React.forwardRef(
  function NumberFormatCustom(props, ref) {
    const { onChange, onBlur, onFocus, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={false} // allow user to type decimals; onBlur can enforce 2 decimals if desired
        allowNegative={true}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value, // raw numeric string without formatting (e.g., "1234.56")
            },
          });
        }}
        onBlur={onBlur}
        onFocus={(e) => {
          if (onFocus) {
            onFocus(e);
          } else {
            e.target.select();
          }
        }}
        {...other}
      />
    );
  },
);

/**
 * AmountTextField is a Material UI TextField that uses NumberFormatCustom as its input.
 * It accepts an amount object with displayAmount and intAmount (in cents).
 * It updates both as the user types and supports live formatting.
 */
export const AmountTextField = ({
  amount,
  setNewAmount,
  required,
  helperText,
  error,
  disabled,
  size,
  id,
  label,
  onBlurCallback,
  onFocusCallback,
  naked,
}) => {
  return (
    <TextField
      id={id || "amount-input"}
      name={`${label}-${id}`}
      label={naked ? "" : label || "Amount"}
      value={amount?.displayAmount || ""}
      onChange={(e) => {
        // e.target.value is a raw numeric string (e.g., "1234.56")
        const rawValue = e.target.value;
        const parsed = parseFloat(rawValue);
        const intAmount = !isNaN(parsed) ? Math.round(parsed * 100) : 0;
        setNewAmount({ displayAmount: rawValue, intAmount });
      }}
      onBlur={onBlurCallback}
      onFocus={(e) => {
        if (onFocusCallback) onFocusCallback(e);
        else e.target.select();
      }}
      InputProps={{
        inputComponent: NumberFormatCustom,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      required={required || false}
      helperText={naked ? "" : helperText || ""}
      error={error || false}
      disabled={disabled || false}
      size={size || "medium"}
      variant={naked ? "standard" : "outlined"}
    />
  );
};

export default AmountTextField;
