// /foundation/budgets/AccountBudgetRow.jsx
import React, { useState, useEffect } from "react";
import { TableRow, TableCell, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AmountTextField from "../../../utilities/reusable_input_components";

// Helper to format cents (integer) into a currency string with commas and two decimals.
const formatCents = (cents) => {
  const value = (cents / 100).toFixed(2);
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Helper to sum monthly values (in cents).
const sumMonths = (months) =>
  Object.values(months).reduce((sum, val) => sum + (parseInt(val, 10) || 0), 0);

// Adjusted distributeDifference: remainder computed as diff - (baseAdjustment * numMonths)
const distributeDifference = (months, diff, monthOrder) => {
  const numMonths = monthOrder.length;
  const baseAdjustment = Math.floor(diff / numMonths);
  const remainder = diff - baseAdjustment * numMonths;
  const newMonths = { ...months };
  monthOrder.forEach((month, index) => {
    newMonths[month] =
      (parseInt(newMonths[month], 10) || 0) +
      baseAdjustment +
      (index === numMonths - 1 ? remainder : 0);
  });
  return newMonths;
};

const AccountBudgetRow = ({
  account,
  initialBudget,
  monthOrder,
  onBudgetChange,
  dense,
  child,
  priorData,
  editable,
}) => {
  // Local state for this row's budget.
  const [budget, setBudget] = useState(initialBudget);

  // Sync local state when the prop changes.
  useEffect(() => {
    setBudget(initialBudget);
  }, [initialBudget]);

  // State for menu dropdown.
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // When the annual input loses focus, adjust monthly values if needed.
  const handleAnnualBlur = () => {
    const currentMonthSum = sumMonths(budget.months);
    if (budget.total !== currentMonthSum) {
      const diff = budget.total - currentMonthSum;
      const updatedMonths = distributeDifference(
        budget.months,
        diff,
        monthOrder,
      );
      const updatedBudget = { ...budget, months: updatedMonths };
      setBudget(updatedBudget);
      onBudgetChange(account.id, updatedBudget);
    }
  };

  // When a monthly input loses focus, recalc the annual total.
  const handleMonthBlur = () => {
    const newAnnual = sumMonths(budget.months);
    const updatedBudget = { ...budget, total: newAnnual };
    setBudget(updatedBudget);
    onBudgetChange(account.id, updatedBudget);
  };

  const handleAnnualChange = (newAmountObj) => {
    const updatedBudget = { ...budget, total: newAmountObj.intAmount };
    setBudget(updatedBudget);
  };

  const handleMonthlyChange = (monthKey, newAmountObj) => {
    const newMonths = { ...budget.months, [monthKey]: newAmountObj.intAmount };
    const updatedBudget = { ...budget, months: newMonths };
    setBudget(updatedBudget);
    onBudgetChange(account.id, updatedBudget);
  };

  // "Divide Evenly" action.
  const handleDivideEvenly = () => {
    const numMonths = monthOrder.length;
    const baseValue = Math.floor(budget.total / numMonths);
    const remainder = budget.total - baseValue * numMonths;
    const newMonths = {};
    monthOrder.forEach((month, index) => {
      newMonths[month] =
        index === numMonths - 1 ? baseValue + remainder : baseValue;
    });
    const updatedBudget = { ...budget, months: newMonths };
    setBudget(updatedBudget);
    onBudgetChange(account.id, updatedBudget);
    handleMenuClose();
  };

  // "Smart Divide" action: use historical data to proportionally distribute the annual total.
  const handleSmartDivide = () => {
    if (!priorData || !priorData.monthlyTotals) return;
    const historicalTotals = priorData.monthlyTotals;
    const totalHistorical = Object.values(historicalTotals).reduce(
      (sum, val) => sum + (parseInt(val, 10) || 0),
      0,
    );
    if (totalHistorical === 0) return; // avoid division by zero
    const newMonths = {};
    monthOrder.forEach((month) => {
      const fraction =
        parseInt(historicalTotals[month] || 0, 10) / totalHistorical;
      newMonths[month] = Math.round(fraction * budget.total);
    });
    const updatedBudget = { ...budget, months: newMonths };
    setBudget(updatedBudget);
    onBudgetChange(account.id, updatedBudget);
    handleMenuClose();
  };

  // "Clear" action: reset annual total and monthly values to zero.
  const handleClearRow = () => {
    const clearedMonths = monthOrder.reduce((acc, month) => {
      acc[month] = 0;
      return acc;
    }, {});
    const updatedBudget = { ...budget, total: 0, months: clearedMonths };
    setBudget(updatedBudget);
    onBudgetChange(account.id, updatedBudget);
    handleMenuClose();
  };

  return (
    <TableRow>
      {/* Account name cell */}
      <TableCell style={child ? { paddingLeft: "2rem" } : {}}>
        {account.accountName || account.id}
      </TableCell>
      {/* Annual input */}
      <TableCell>
        <AmountTextField
          id={`annual-${account.id}`}
          label="Annual"
          amount={{
            displayAmount: formatCents(budget.total),
            intAmount: budget.total,
          }}
          setNewAmount={handleAnnualChange}
          onBlurCallback={handleAnnualBlur}
          size={dense ? "small" : "medium"}
          naked={true}
          disabled={!editable}
        />
      </TableCell>
      {/* Actions cell: drop-down menu */}
      <TableCell>
        <IconButton size="small" onClick={handleMenuOpen} disabled={!editable}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}>
          <MenuItem onClick={handleDivideEvenly}>Divide Evenly</MenuItem>
          <MenuItem
            onClick={handleSmartDivide}
            disabled={!priorData || !priorData.monthlyTotals}>
            Smart Divide
          </MenuItem>
          <MenuItem onClick={handleClearRow}>Clear</MenuItem>
        </Menu>
      </TableCell>
      {/* Monthly inputs */}
      {monthOrder.map((month) => (
        <TableCell key={`${account.id}-${month}`}>
          <AmountTextField
            id={`${month}-${account.id}`}
            label={month.toUpperCase()}
            amount={{
              displayAmount: formatCents(budget.months[month] || 0),
              intAmount: budget.months[month] || 0,
            }}
            setNewAmount={(newAmountObj) =>
              handleMonthlyChange(month, newAmountObj)
            }
            onBlurCallback={handleMonthBlur}
            size={dense ? "small" : "medium"}
            naked={true}
            disabled={!editable}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default AccountBudgetRow;
