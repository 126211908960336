// /reports/income_statement/income_statement.js
import React, { useState, useEffect, useContext } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import UserContext from "../../../assets/user_context.jsx";

import {
  LargeLineText,
  PageWrapper,
  ButtonDiv,
  LineText,
  HorizontalWrapper,
} from "../reports_styles.jsx";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Switch, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import withSubscriptionProtection from "../../../services/with_subscription.jsx";
import { Inventory2Outlined } from "@mui/icons-material";
import { convertToJSON, exportReportXlsx } from "../export_report_xlsx.jsx";
import TransactionsDrilling from "../transaction_drilling/transactions_drilling.jsx";
import { useTrackFundsDisplayedCount } from "../report_helper_functions.js";
import TableHeader from "../funds_header.jsx";
import AccountTypeRows, {
  FundSummaryColumns,
  NetIncomeColumns,
} from "../account_type_rows.jsx";
import IncomeStatementPDFExport from "../pdf/IncomeStatementPDFExport.jsx";
dayjs.extend(isBetween);

const IncomeStatementList = () => {
  const {
    accounts,
    accountsHierarchy,
    fundsHierarchy,
    funds,
    contacts,
    orgData,
    org,
  } = useContext(UserContext);

  const [dateRange, setDateRange] = useState([
    dayjs().startOf("year"),
    dayjs(),
  ]);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(() => dayjs(new Date()));
  const [incomeStatementReportsData, setIncomeStatementReportsData] = useState(
    {},
  );
  const [showFunds, setShowFunds] = useState(true);
  const [txToDrill, setTxToDrill] = useState(null);
  const [txModalOpen, setTxModalOpen] = useState(false);
  const [fundTransactions, setFundTransactions] = useState({});
  const [numberOfFundsShown, setNumberOfFundsShown] = useState(0);
  const [fundsDetailsToDisplay, setFundsDetailsToDisplay] = useState([]);
  const [accountCollapseState, setAccountCollapseState] = useState({
    groups: [], // Will be an array of booleans, one per account group.
    accounts: {}, // Will map account IDs (or numbers) to booleans.
  });

  // New state variable for showing cents
  const [withCents, setWithCents] = useState(false);
  // New state variable for loading spinner
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (fundsHierarchy?.groups?.length > 0) {
      const fundsDetails = fundsHierarchy.groups.map((group) => ({
        show: true,
        funds: group.funds.map(() => ({ show: true })),
      }));
      setFundsDetailsToDisplay(fundsDetails);
    }
  }, [fundsHierarchy]);

  const toggleFundVisibility = ({ groupIndex, fundIndex }) => {
    setFundsDetailsToDisplay((prevGroups) =>
      prevGroups.map((group, gIndex) => {
        if (gIndex === groupIndex) {
          if (fundIndex === null) {
            return { ...group, show: !group.show };
          } else {
            const funds = group.funds.map((fund, fIndex) =>
              fIndex === fundIndex ? { show: !fund.show } : fund,
            );
            return { ...group, funds };
          }
        }
        return group;
      }),
    );
  };

  useEffect(() => {
    if (accountsHierarchy?.groups?.length > 0) {
      const newGroupsState = accountsHierarchy.groups.map(() => true);
      const newAccountsState = {};
      accountsHierarchy.groups.forEach((group) => {
        group.accounts.forEach((account) => {
          newAccountsState[account.accountNumber] = true;
        });
      });
      setAccountCollapseState({
        groups: newGroupsState,
        accounts: newAccountsState,
      });
    }
  }, [accountsHierarchy]);

  useEffect(() => {
    console.log(fundsDetailsToDisplay);
  }, [fundsDetailsToDisplay]);

  useTrackFundsDisplayedCount({
    fundsHierarchy,
    fundsDetailsToDisplay,
    setNumberOfFundsShown,
  });

  useEffect(() => {
    if (dateRange[1]) {
      setMaxDate(dayjs(dateRange[1]));
    }
    if (dateRange[0]) {
      setMinDate(dayjs(dateRange[0]));
    }
  }, [dateRange]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      // Clear out previous report data immediately when the date range changes.
      setIncomeStatementReportsData({});
      setLoading(true);
      const functions = getFunctions();
      const calcReportCloud = httpsCallable(functions, "calcReportV2Cloud");

      calcReportCloud({
        orgId: org,
        startDate: dateRange[0],
        endDate: dateRange[1],
        accountsHierarchy,
        fundsHierarchy,
        accounts,
        funds,
        type: "incomeStatement",
      })
        .then((result) => {
          console.log("Income Statement Report Data: ", result.data);
          setIncomeStatementReportsData(result.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error calling calcReportV2Cloud:", error);
          setLoading(false);
        });
    }
  }, [dateRange, accounts, accountsHierarchy, fundsHierarchy, funds, orgData]);

  useEffect(() => {
    if (Object.keys(incomeStatementReportsData).length > 0) {
      console.log("Income Statement Report Data: ", incomeStatementReportsData);
      console.log(
        incomeStatementReportsData[Object.keys(incomeStatementReportsData)[0]]
          .income,
      );
    }
  }, [incomeStatementReportsData]);

  const drillTx = ({ fund, account }) => {
    setTxToDrill({ fund, account });
    setTxModalOpen(true);
  };

  return (
    <div>
      <PageWrapper>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <HorizontalWrapper style={{ paddingBottom: "1rem" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            />
            <DatePicker
              label="Starting Date"
              value={dateRange[0]}
              onChange={(newValue) => {
                setDateRange([newValue, dateRange[1]]);
              }}
              maxDate={maxDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={true}
                  variant="outlined"
                  color="secondary"
                />
              )}
            />
            <LargeLineText>TO</LargeLineText>
            <DatePicker
              label="Ending Date"
              value={dateRange[1]}
              onChange={(newValue) => {
                setDateRange([dateRange[0], newValue]);
              }}
              disableFuture
              minDate={minDate}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={true}
                  variant="outlined"
                  color="secondary"
                />
              )}
            />
            {/* New switch for toggling cents */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
              }}>
              <LineText>Show Cents</LineText>
              <Switch
                checked={withCents}
                onChange={(e) => setWithCents(e.target.checked)}
                color="primary"
              />
            </div>
            <div style={{ width: "20vw" }} />
            <ButtonDiv>
              <LineText style={{ whiteSpace: "nowrap" }}>
                Export to XLSX
              </LineText>
              <Inventory2Outlined
                fontSize="medium"
                onClick={() => {
                  const JSONReport = convertToJSON({
                    reportData: incomeStatementReportsData,
                    funds,
                  });
                  console.log("JSON Report: ", JSONReport);
                  exportReportXlsx({
                    JSONReport,
                    reportName: "Income Statement",
                    dateRange: `${dateRange[0].format(
                      "MM/DD/YYYY",
                    )}-${dateRange[1].format("MM/DD/YYYY")}`,
                    orgName: orgData?.orgName || "Test Organization",
                  });
                }}
              />
            </ButtonDiv>
            <div style={{ width: "60px" }} />
            <div>
              {/* Only render PDF export if report data exists */}
              {/* {Object.keys(incomeStatementReportsData).length > 0 && (
                <IncomeStatementPDFExport
                  reportData={incomeStatementReportsData}
                  fundsDetailsToDisplay={fundsDetailsToDisplay}
                  accountCollapseState={accountCollapseState}
                  dateRange={dateRange}
                  withCents={withCents}
                />
              )} */}
            </div>
          </HorizontalWrapper>
        </LocalizationProvider>
        <div style={{ height: "25px" }} />

        <TransactionsDrilling
          txModalOpen={txModalOpen}
          setTxToDrill={setTxToDrill}
          setTxModalOpen={setTxModalOpen}
          fundTransactions={fundTransactions}
          contacts={contacts}
          txToDrill={txToDrill}
        />

        {/* Conditionally render the spinner or the report table */}
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "50vh",
            }}>
            <LargeLineText>Generating</LargeLineText>
            <CircularProgress />
          </div>
        ) : Object.keys(incomeStatementReportsData).filter(
            (fundId) => fundId !== "total",
          ).length > 0 ? (
          <div
            id="income-statement-table"
            style={{
              paddingTop: "1.75rem",
              width: "100%",
              overflowX: "auto",
              maxWidth: "92vw",
            }}>
            <table>
              <TableHeader
                showFunds={showFunds}
                fundsDetailsToDisplay={fundsDetailsToDisplay}
                toggleFundVisibility={toggleFundVisibility}
                setShowFunds={setShowFunds}
                numberOfFundsShown={numberOfFundsShown}
              />
              {/* ...existing table header and rows for Income and Expenses */}
              <tbody>
                <AccountTypeRows
                  accountType="Income"
                  balanceSheetObject={incomeStatementReportsData}
                  fundsDetailsToDisplay={fundsDetailsToDisplay}
                  showFunds={showFunds}
                  withCents={withCents}
                  accountCollapseState={accountCollapseState}
                  setAccountCollapseState={setAccountCollapseState}
                />
                <AccountTypeRows
                  accountType="Expenses"
                  balanceSheetObject={incomeStatementReportsData}
                  fundsDetailsToDisplay={fundsDetailsToDisplay}
                  showFunds={showFunds}
                  withCents={withCents}
                  accountCollapseState={accountCollapseState}
                  setAccountCollapseState={setAccountCollapseState}
                />
                <NetIncomeColumns
                  incomeStatementData={incomeStatementReportsData}
                  fundsDetailsToDisplay={fundsDetailsToDisplay}
                  showFunds={showFunds}
                  withCents={withCents}
                />
                {incomeStatementReportsData
                  .filter((item) =>
                    [
                      "Beginning Fund Balance",
                      "Net Income/(Loss)",
                      "Other Fund Balance Movements",
                      "Ending Fund Balance",
                    ].includes(item.accountTypeName),
                  )
                  .map((fundSummaryItem) => (
                    <FundSummaryColumns
                      key={fundSummaryItem.accountTypeName}
                      fundSummary={fundSummaryItem}
                      fundsHierarchy={fundsHierarchy}
                      fundsDetailsToDisplay={fundsDetailsToDisplay}
                      withCents={withCents}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        ) : null}
        <div style={{ height: "25px" }} />
      </PageWrapper>
    </div>
  );
};

const IncomeStatement = withSubscriptionProtection(IncomeStatementList);

export default IncomeStatement;
