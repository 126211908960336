import React, { useContext, useState, useEffect } from "react";
import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  doc,
  getDoc,
  setDoc,
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  deleteDoc,
  runTransaction,
} from "firebase/firestore";
import { useAuth } from "../../../services/use-auth.js";
import UserContext from "../../../assets/user_context.jsx";
import CreateEditBudget from "./CreateEditBudget.jsx";
import { LockClockOutlined, LockOutlined } from "@mui/icons-material";

const Budgets = () => {
  const { orgData, org, accounts, accountsHierarchy } = useContext(UserContext);
  const authHook = useAuth();
  const [selectedYear, setSelectedYear] = useState("");
  const [budgetDoc, setBudgetDoc] = useState(null);
  const [loadingBudget, setLoadingBudget] = useState(false);

  // Load budget for selected year:
  // - If a draft exists, load it (editing mode).
  // - Else load the latest published version in view-only mode.
  const loadBudgetForYear = async () => {
    if (!selectedYear) return;
    setLoadingBudget(true);
    const draftId = `${selectedYear}-draft`;
    const draftRef = doc(authHook.db, "orgs", org, "budgets", draftId);
    const draftSnap = await getDoc(draftRef);
    if (draftSnap.exists()) {
      // Draft exists – load it.
      setBudgetDoc({
        id: draftId,
        ...draftSnap.data(),
        accountsHierarchy,
        accounts,
      });
    } else {
      // No draft; load published version.
      const budgetsRef = collection(authHook.db, "orgs", org, "budgets");
      const q = query(
        budgetsRef,
        where("budgetBeginsInYear", "==", selectedYear),
        where("published", "==", true),
        orderBy("version", "desc"),
        limit(1),
      );
      const querySnap = await getDocs(q);
      if (!querySnap.empty) {
        const publishedDoc = querySnap.docs[0];
        setBudgetDoc({
          id: publishedDoc.id,
          ...publishedDoc.data(),
          accountsHierarchy,
          accounts,
        });
      } else {
        setBudgetDoc(null);
      }
    }
    setLoadingBudget(false);
  };

  useEffect(() => {
    loadBudgetForYear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, authHook.db, org, accountsHierarchy, accounts]);

  // Create a new budget (draft) if none exists.
  const handleCreateBudget = async () => {
    const draftId = `${selectedYear}-draft`;
    const defaultBudgets = {};
    accounts.forEach((acct) => {
      if (acct.accountType === "Income" || acct.accountType === "Expenses") {
        const defaultMonths = {};
        const allMonths = [
          "jan",
          "feb",
          "mar",
          "apr",
          "may",
          "jun",
          "jul",
          "aug",
          "sep",
          "oct",
          "nov",
          "dec",
        ];
        const startIndex = allMonths.indexOf(
          orgData.financialYearStart.toLowerCase(),
        );
        const monthOrder =
          startIndex === -1
            ? allMonths
            : [
                ...allMonths.slice(startIndex),
                ...allMonths.slice(0, startIndex),
              ];
        monthOrder.forEach((month) => {
          defaultMonths[month] = 0;
        });
        defaultBudgets[acct.id] = {
          total: 0,
          months: defaultMonths,
          byFund: {},
          byLabel: {},
        };
      }
    });
    const newBudget = {
      published: false,
      budgetBeginsInYear: selectedYear,
      budgets: defaultBudgets,
      accountsHierarchy,
      accounts,
      changeCount: 0,
    };
    try {
      const draftRef = doc(authHook.db, "orgs", org, "budgets", draftId);
      await setDoc(draftRef, newBudget);
      setBudgetDoc({ id: draftId, ...newBudget, accountsHierarchy, accounts });
    } catch (err) {
      console.error("Error creating budget:", err);
    }
  };

  // Convert a published budget to a draft for editing.
  const handleConvertToDraft = async () => {
    if (!selectedYear || !budgetDoc) return;
    try {
      const draftId = `${selectedYear}-draft`;
      const draftRef = doc(authHook.db, "orgs", org, "budgets", draftId);
      const publishedData = { ...budgetDoc };
      // Remove published-only properties and mark as draft.
      delete publishedData.version;
      publishedData.published = false;
      publishedData.sourceVersion = budgetDoc.id;
      publishedData.changeCount = 0;
      delete publishedData.id;
      await setDoc(draftRef, publishedData);
      const draftSnap = await getDoc(draftRef);
      if (draftSnap.exists()) {
        setBudgetDoc({
          id: draftId,
          ...draftSnap.data(),
          accountsHierarchy,
          accounts,
        });
      }
    } catch (err) {
      console.error("Error converting to draft:", err);
    }
  };

  // Publish the draft using a Firestore transaction.
  const handlePublish = async () => {
    try {
      const budgetsRef = collection(authHook.db, "orgs", org, "budgets");
      const q = query(
        budgetsRef,
        where("budgetBeginsInYear", "==", selectedYear),
        where("published", "==", true),
        orderBy("version", "desc"),
        limit(1),
      );
      const querySnap = await getDocs(q);
      let newVersion = 1;
      if (!querySnap.empty) {
        const highestDoc = querySnap.docs[0];
        const highestVersion = highestDoc.data().version || 1;
        newVersion = highestVersion + 1;
      }
      const newPublishedId = `${selectedYear}-version-${newVersion}`;
      const draftId = `${selectedYear}-draft`;
      const draftRef = doc(authHook.db, "orgs", org, "budgets", draftId);
      await runTransaction(authHook.db, async (transaction) => {
        const draftSnap = await transaction.get(draftRef);
        if (!draftSnap.exists()) {
          throw new Error("Draft does not exist");
        }
        const draftData = draftSnap.data();
        const newPublishedData = {
          ...draftData,
          published: true,
          version: newVersion,
        };
        const newPublishedRef = doc(
          authHook.db,
          "orgs",
          org,
          "budgets",
          newPublishedId,
        );
        transaction.set(newPublishedRef, newPublishedData);
        transaction.delete(draftRef);
      });
      console.log("Budget published as", newPublishedId);
      loadBudgetForYear();
    } catch (err) {
      console.error("Error publishing budget:", err);
    }
  };

  return (
    <Paper style={{ padding: "1rem" }}>
      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          gap: "4rem",
          alignItems: "flex-start",
        }}>
        <FormControl
          fullWidth
          style={{ minWidth: 200, marginBottom: "1rem", maxWidth: 300 }}>
          <InputLabel id="select-year-label">Select Budget Year</InputLabel>
          <Select
            labelId="select-year-label"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            label="Select Budget Year">
            <MenuItem value={"2023"}>2023</MenuItem>
            <MenuItem value={"2024"}>2024</MenuItem>
            <MenuItem value={"2025"}>2025</MenuItem>
          </Select>
        </FormControl>
        {selectedYear && loadingBudget && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: "1rem",
            }}>
            <CircularProgress size={32} />
            <Typography>Checking Budgets...</Typography>
          </div>
        )}
        {selectedYear && !loadingBudget && budgetDoc === null && (
          <div>
            <Typography variant="h6">
              No budget exists for {selectedYear}.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateBudget}>
              Create Budget for {selectedYear}
            </Button>
          </div>
        )}
        {selectedYear &&
          !loadingBudget &&
          budgetDoc !== null &&
          budgetDoc.published && (
            <div
              style={{
                marginBottom: "1rem",
                display: "flex",
                gap: "4rem",
                flexDirection: "row",
              }}>
              <Typography variant="h6">
                Viewing Published Budget (Version: {budgetDoc.version || "N/A"})
              </Typography>
              <Button
                sx={{ fullWidth: "true" }}
                variant="contained"
                color="secondary"
                onClick={handleConvertToDraft}>
                Edit Budget
                <LockOutlined />
              </Button>
            </div>
          )}
      </div>
      {selectedYear && !loadingBudget && budgetDoc !== null && (
        <>
          <CreateEditBudget
            selectedYear={selectedYear}
            initialBudgetDoc={budgetDoc}
            orgData={orgData}
            org={org}
            editable={!budgetDoc.published}
          />
          {!budgetDoc.published && (
            <Button
              variant="contained"
              color="primary"
              onClick={handlePublish}
              style={{ marginTop: "1rem" }}>
              Publish Budget
            </Button>
          )}
        </>
      )}
    </Paper>
  );
};

export default Budgets;
