// /reports/pdf/pdfStyles.js
import { StyleSheet } from "@react-pdf/renderer";

export const pdfConfig = {
  mmToPt: 2.83465,
  page: {
    portraitWidthMM: 210,
    landscapeWidthMM: 297,
    marginsMM: [10, 10, 10, 10], // [left, top, right, bottom]
  },
  text: {
    characterWidthFactor: 2.5,
    // Minimum column width (to fit at least 6 characters)
    columnWidthFloorMM: 6 * 2.5,
    // Dynamic font scaling settings
    fontScalingFactor: 4.5, // increase to make the font smaller
    minFontSize: 6,
    maxFontSize: 12,
  },
  layout: {
    pagePaddingTop: 30,
    fixedHeaderTop: 1,
    combinedHeaderTitleFontOffset: 4,
    combinedHeaderDateFontOffset: 2,
    contentMarginTop: 10,
  },
  debug: {
    showBorders: false,
    // If showBorders is false, the report will look normal. Set to true to see PDF layout for debug
    borders: {
      pageBoundary: "1px solid blue",
      sectionContainer: "1px dashed orange",
      tableContainer: "1px dashed green",
      headerContainer: "1px solid magenta",
      headerRow: "1px solid red",
      accountCell: "1px solid blue",
      fundCell: "1px solid green",
      totalCell: "1px solid orange",
      groupSubtotal: "1px solid purple",
      summaryRow: "1px solid gray",
      spacerRow: "1px dashed pink",
      groupRow: "1px solid teal",
    },
    backgrounds: {
      headerContainer: "rgba(255,0,255,0.1)",
      headerRow: "rgba(255,0,0,0.1)",
      tableContainer: "rgba(0,255,0,0.1)",
      groupSubtotal: "rgba(128,0,128,0.1)",
    },
  },
};

export const layoutConfig = pdfConfig.layout;

export const baseStyles = StyleSheet.create({
  page: { padding: 10 },
  pageWithHeader: { padding: 10, paddingTop: layoutConfig.pagePaddingTop },
  table: { display: "table", width: "100%", marginBottom: 10 },
  tableRow: { flexDirection: "row" },
  tableCell: { padding: 3 },
  tableCellHeader: {
    padding: 3,
    fontWeight: "bold",
    borderBottom: "1px solid #000",
  },
});
