import React, { useState } from "react";
import { ProvideAuth } from "../services/use-auth";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  MainContainer,
  ContentContainer,
  TopNavAndContentContainer,
} from "./app_styles.js";

import Nav from "../nav/nav.js";
import UserProvider from "../services/context_and_index";

//Parent Locations
import Banking from "../parent_nav_components/banking.js";
// import Reports from "../parent_nav_components/reports.js";
import Account from "../parent_nav_components/account.js";
import Foundation from "../parent_nav_components/foundation.js";
import Contacts from "../locations/contacts/contacts.jsx";
import History from "../locations/history/history";
import SignInPage from "../session_management/signIn";
import SignUpPage from "../session_management/signUp";

//Sub Locations/Components
import AdminOrgs from "../locations/admin/admin_orgs.jsx";
import AdminUsers from "../locations/admin/admin_users.jsx";
import ChartOfAccounts from "../locations/foundation/chart_of_accounts/chart_of_accounts.jsx";
import FundsList from "../locations/foundation/funds_list/funds_list.js";
import Labels from "../locations/foundation/labels.js";
import Budgets from "../locations/foundation/budgets/budgets.js";
import BankAccounts from "../locations/banking/banking/bank_accounts.js";
import CreditCards from "../locations/banking/credit_cards/credit_cards.js";

import Reconciliation from "../locations/banking/reconciliation/reconciliation.js";
import JournalEntry from "../locations/transactions/journal_entry.js";
import BalanceSheet from "../locations/reports/balance_sheet/balance_sheet.js";
import IncomeStatement from "../locations/reports/income_statement/income_statement.js";
import GeneralLedger from "../locations/reports/general_ledger.js";
import FundSummary from "../locations/reports/fund_summary.js";
import DonationStatements from "../locations/reports/donation_statements.jsx";
import OrgInfo from "../locations/account/org_management/org_info.js";
import Users from "../locations/account/users.js";
import Settings from "../locations/account/settings/settings.jsx";
import Preferences from "../locations/account/preferences.js";
import Home from "../locations/home/home.js";
import ReconciliationReport from "../locations/reports/reconciliation.jsx";
import Donors from "../locations/contacts/donors.jsx";
import Donations from "../locations/donations/donations.jsx";

// import Transactions from "../parent_nav_components/transactions.js";
// import AccountsPayable from "../locations/transactions/accounts_payable.js";
// import AccountsReceivable from "../locations/transactions/accounts_receivable.js";
// import Transfers from "../locations/transactions/transfers.js";
// import Integrations from "../locations/banking/integrations.js";
// import BudgetToActual from "../locations/reports/budget_to_actual.js";
import TransactionsList from "../locations/reports/transactions_list.jsx";
import WelcomeToTheClub from "../locations/welcome_to_the_club";

import NoMatch from "../nav/no_match";

// import Test from "../test_file";
import TopNav from "../nav/top_nav.jsx";

const App = () => {
  const AppInSession = () => {
    const [collapsed, setCollapsed] = useState(false);
    // In your App.js

    // Pass db and org to your UserProvider
    return (
      <UserProvider>
        <BrowserRouter>
          <MainContainer className={collapsed ? "collapsed" : ""}>
            <Nav collapsed={collapsed} setCollapsed={setCollapsed} />
            <TopNavAndContentContainer
              className={collapsed ? "collapsed" : ""}
              id="top-nav-and-content-container"
              collapsed={collapsed}>
              <TopNav collapsed={collapsed} />
              <ContentContainer
                style={{ width: "97%" }}
                id="content-container"
                className={collapsed ? "collapsed" : ""}
                collapsed={collapsed}>
                <Routes>
                  <Route path="/" element={<Home />} />

                  <Route path="/signin" element={<SignInPage />} />
                  <Route path="/signup" element={<SignUpPage />} />
                  <Route path="/foundation" element={<Foundation />} />
                  <Route path="/banking" element={<Banking />} />
                  {/* <Route path="/transactions" element={<Transactions />} /> */}
                  <Route path="/contacts/contacts" element={<Contacts />} />
                  <Route path="/contacts/donors" element={<Donors />} />
                  {/* <Route path="/reports" element={<Reports />} /> */}
                  <Route path="/history" element={<History />} />
                  <Route path="/account-settings" element={<Account />} />
                  <Route path="/admin-orgs" element={<AdminOrgs />} />
                  <Route path="/admin-users" element={<AdminUsers />} />
                  {/* All subroutes here */}
                  <Route
                    path="/upkeep/bank-accounts"
                    element={<BankAccounts />}
                  />
                  <Route
                    path="/upkeep/credit-cards"
                    element={<CreditCards />}
                  />
                  {/* <Route
                    path="/banking/integrations"
                    element={<Integrations />}
                  /> */}
                  <Route
                    path="/upkeep/reconciliation"
                    element={<Reconciliation />}
                  />
                  <Route path="/upkeep/donations" element={<Donations />} />
                  {/* <Route
                    path="/transactions/accounts-payable"
                    element={<AccountsPayable />}
                  />
                  <Route
                    path="/transactions/accounts-receivable"
                    element={<AccountsReceivable />}
                  /> */}
                  <Route
                    path="/upkeep/journal-entry"
                    element={<JournalEntry />}
                  />
                  {/* <Route
                    path="/transactions/transfers"
                    element={<Transfers />}
                  /> */}
                  <Route
                    path="/reports/balance-sheet"
                    element={<BalanceSheet />}
                  />
                  <Route
                    path="/reports/income-statement"
                    element={<IncomeStatement />}
                  />
                  <Route
                    path="/reports/general-ledger"
                    element={<GeneralLedger />}
                  />
                  <Route
                    path="/reports/fund-summary"
                    element={<FundSummary />}
                  />
                  {/* <Route
                    path="/reports/budget-to-actual"
                    element={<BudgetToActual />}
                  /> */}
                  <Route
                    path="/reports/transactions-list"
                    element={<TransactionsList />}
                  />
                  <Route
                    path="/reports/reconciliation"
                    element={<ReconciliationReport />}
                  />
                  <Route
                    path="/reports/donations"
                    element={<DonationStatements />}
                  />
                  <Route
                    path="/foundation/chart-of-accounts"
                    element={<ChartOfAccounts />}
                  />
                  <Route
                    path="/foundation/funds-list"
                    element={<FundsList />}
                  />
                  <Route path="/foundation/labels" element={<Labels />} />
                  <Route path="/foundation/budgets" element={<Budgets />} />
                  <Route path="/account/org-management" element={<OrgInfo />} />
                  <Route path="/account/users" element={<Users />} />
                  <Route path="/account/settings" element={<Settings />} />
                  <Route
                    path="/account/preferences"
                    element={<Preferences />}
                  />
                  <Route
                    path="/account/welcome-to-the-club"
                    element={<WelcomeToTheClub />}
                  />
                  <Route path="*" element={<NoMatch />} />
                  {/* <Route path="/test" element={<Test />} /> */}
                </Routes>
              </ContentContainer>
            </TopNavAndContentContainer>
          </MainContainer>
        </BrowserRouter>
      </UserProvider>
    );
  };
  return (
    <ProvideAuth>
      <AppInSession />
    </ProvideAuth>
  );
};

export default App;
